.checked-in {
  background-color: rgba(146, 207, 92, 0.4);
}

.flagged {
  background-color: #ffd1d1;
}

.contacted {
  background-color: #FEFCD0;
}

.no-show {
  background-color: #C1C1C1;
}