.floatLeft {
  float: left;
}

.menu {
  padding: 0;
  list-style: none;
}

.menu li {
  padding: 0 15px;
}

.menu li a {
  color: rgb(91, 91, 91);
  text-decoration: none;
}